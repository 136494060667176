import React from 'react';
import LogoImg from '../images/svg/logo.svg'
import LogoImgBlack from '../images/svg/logo_black.svg'
import { Link } from 'gatsby';
import Logo from '../images/svg/mobileLogo.svg'
import LogoBlack from '../images/svg/mobileLogo_black.svg'
import { useState } from "react"

function Nav(props) {
  const { data } = props

  const [active, setActive] = useState(false)
  
  const contactClass =(data.path.indexOf("contact") > -1) ? 'contact-nav' : ''

  const handleMenu = (e) => {
    setActive(!active)
  }


  return (
    <nav className={`navbar ${contactClass}`} id={active ? 'active-mobile' : ''}>
      <div className="desktopNavbar">
        <div className="logoMain">
          <Link to="/" className="logo-main">
            <LogoImg className="logo-light" alt="logo" />
            <LogoImgBlack className="logo-dark" alt="logo" />
          </Link>
          <Link to="/">
            <Logo className="scrollLogo" alt="logo" />
            <LogoBlack className="scrollLogoBlack" alt="logo" />
          </Link>
        </div>
        <ul>
          <li>
            <Link className={'mainNavLink'} activeClassName="active-nav_link" partiallyActive={false}
              to="/">
              Work
            </Link>
          </li>
          <li>
            <Link className={'mainNavLink'} activeClassName="active-nav_link" partiallyActive={false}
              to="/info">Info</Link>
          </li>
          <li>
            <Link className={'mainNavLink'} activeClassName="active-nav_link" partiallyActive={false}
              to="/contact">
              Hello</Link>
          </li>
        </ul>

        <div className="sideSocials">
          <a href="https://www.instagram.com/bythepublic/" className={'iconIG'} target="_blank" rel="noreferrer">Instagram</a>
          <a href="https://www.linkedin.com/company/public-school-a-creative-agency/" className={'iconTwitter'} target="_blank" rel="noreferrer">Linkedin</a>
        </div>
      </div>
      
      <div className={active ? 'mobileMenu mobile-menu_active' : 'mobileMenu'} onClick={handleMenu}>
        <ul>
          <li>
            <Link className={'mainNavLink'} to="/" >Work</Link>
          </li>
          <li>
            <Link className={'mainNavLink'} to="/info">Info</Link>
          </li>
          <li>
            <Link className={'mainNavLink contact-link'} to="/contact" >Hello</Link>
          </li>
        </ul>
        <div className="mobileMenu-socials">
          <a href="https://www.instagram.com/bythepublic/" target="_blank" rel="noreferrer">Instagram</a>
          <a href="https://www.linkedin.com/company/public-school-a-creative-agency/" target="_blank" rel="noreferrer">Linkedin</a>
        </div>
      </div>

      <div className="mobileNavbar">
        {/* <span className="modeChangeSpace"></span> */}
        <span className="mobileLogo"><Link to="/" ><LogoImg alt="logo" /></Link></span>
        <span className="mobileLogoBlack"><LogoBlack alt="logo" /></span>

        <div className={active ? 'hamburgerMenu active-menu' : 'hamburgerMenu'} onClick={handleMenu}>
          <div className="bar1" id="bar-1">.</div>
          <div className="bar2" id="bar-2">.</div>
        </div>

      </div>

    </nav>
  )
}

export default Nav