import React from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import "@fontsource/newsreader";
import "@fontsource/newsreader/200-italic.css";
import "@fontsource/newsreader/300-italic.css";
import "@fontsource/newsreader/400-italic.css";
import "@fontsource/newsreader/500-italic.css";
import "@fontsource/newsreader/600-italic.css";
import "@fontsource/newsreader/700-italic.css";
import "@fontsource/newsreader/800-italic.css";

export default function Layout({ children, data, pageID }) {
  const isBrowser = typeof window !== "undefined";
  let themeBTN = "";
  if (isBrowser) {
    if (window.location.href.indexOf("contact") > -1) {
      themeBTN = "mobile-theme";
    }
  }
  const [theme, setTheme] = React.useState(true);

  React.useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src =
      "https://cdn.matomo.cloud/czarnowski.matomo.cloud/container_oSzADS1t.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <main className={`${theme ? "dark" : "light"}`} id={`${pageID}`}>
      {/* <button className={'themeButton ' + themeBTN} onClick={()=>setTheme(!theme)}/> */}
      <Nav data={data}></Nav>
      {children}
      <Footer />
    </main>
  );
}
