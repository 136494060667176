import { useEffect, useState, useRef } from 'react'
import { newBlockData } from './homeData'

/**
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
function useKeypress(key, action) {
  useEffect(() => {
    if (typeof window === 'undefined') return

    function onKeyup(e) {
      if (e.key === key) action()
    }

    window.addEventListener('keyup', onKeyup)

    return () => window.removeEventListener('keyup', onKeyup)
  })
}

const useMouse = () => {
  const [mouse, setMouse] = useState({ x: 0, y: 0, el: null, c: null })

  useEffect(() => {
    const setFromEvent = (e) => {
      setMouse({ x: e.clientX, y: e.clientY, el: e.target.tagName, c: e.target.className })
    }


    window.addEventListener("mousemove", setFromEvent)
    window.addEventListener("scroll", setFromEvent)

    return () => {
      window.removeEventListener("mousemove", setFromEvent)
      window.removeEventListener("scroll", setFromEvent)
    }
  }, [])

  return mouse
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export {
  newBlockData,
  useKeypress,
  useInterval,
  useMouse
}