import * as React from "react";

export default function Footer({ children }) {
  const isBrowser = typeof window !== "undefined";
  var contactString = "";
  var mobileText = "";
  if (isBrowser) {
    if (window.location.href.indexOf("contact") > -1) {
      contactString = (
        <p>
          We’ve come this far together. Why stop now?{" "}
          <a className={"iconNextArrow"} href="/">
            See our work
          </a>{" "}
          or{" "}
          <a className={"iconNextArrow"} href="/info">
            learn more
          </a>{" "}
          about Public School.<span className="spacer"></span>
        </p>
      );
      mobileText = (
        <span>
          We’ve come this far together. Why stop now?{" "}
          <a className={"iconNextArrow"} href="/">
            See our work
          </a>{" "}
          or{" "}
          <a className={"iconNextArrow"} href="/info">
            learn more
          </a>{" "}
          about Public School.
        </span>
      );
    } else {
      contactString = (
        <p>
          This is the end, but it could be the beginning. &nbsp;
          <a className={"iconNextArrow"} href="/info">
            Learn more
          </a>{" "}
          about Public School and{" "}
          <a className={"iconNextArrow"} href="/contact">
            get in touch
          </a>
          . <span className="spacer"> </span>
        </p>
      );
      mobileText = (
        <span>
          This is the end, but it could be the beginning. &nbsp;
          <a className={"iconNextArrow"} href="/info">
            Learn more
          </a>{" "}
          about Public School and{" "}
          <a className={"iconNextArrow"} href="/contact">
            get in touch
          </a>
          .
        </span>
      );
    }
  }
  return (
    <section id="footer" className="container footerWrapper">
      <div className="footerTop">
        <div className="mobileFooterText">{mobileText}</div>
        {contactString}
        <span> </span>
      </div>
      <div className="mobile-socials">
        <a
          className={"social-link"}
          href="https://www.instagram.com/bythepublic/"
          target="_blank"
          rel="noreferrer">
          Instagram
        </a>
        <a
          className={"social-link"}
          href="https://www.linkedin.com/company/public-school-a-creative-agency/"
          target="_blank"
          rel="noreferrer">
          Linkedin
        </a>
        <a className={"social-link"} href="mailto:hello@public-school.com">
          hello@public-school.com
        </a>
      </div>
      <div className="footerBottom">
        <div className="footerBottomLeft">
          <p>
            © Public School Agency, Dba of CDS. 2024. {" "}
            <a
              className={"iconNextArrow"}
              target="_blank"
              rel="noreferrer"
              href="https://czarnowskicollective.com/privacy">
              Privacy
            </a>
            .
          </p>
        </div>
        <div className="footerBottomRight">
          <p>
            <a
              className={"iconNextArrow"}
              href="mailto:hello@public-school.com">
              hello@public-school.com
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
