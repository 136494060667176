import toyotaGazoo from '../../images/home-toyota-gazoo.jpeg'
/* import porscheUnseen from '../../images/home-porsche.jpeg' */
import porscheUnseen from '../../images/pu-img-banner-up.jpg'
import landroverLeadership from '../../images/home-landrover-leadership.jpeg'
import misfitWrapping from '../../images/home-misfit-wrapping.jpeg'
import fromWhereIMINI from '../../images/home-from-where-imini.jpeg'
import babo from '../../images/home-babo.gif'
import michelinFamily from '../../images/home-michelin-family.png'
import cannacon from '../../images/home-cannacon.jpeg'
import misfitGrinch from '../../images/home-jt.gif'
import givingKitchen from '../../images/home-giving-kitchen.png'
import toyotaMotorThrills from '../../images/home-toyota-motor-thrills.jpeg'
import toyotaSocial from '../../images/home-toyota-social.gif'

import raybanSunday from '../../images/home-block-rayban.png'
import mclarenUncovered from '../../images/home-block-mclaren.png'

const newBlockData = [
    { 
        project: 'ray-ban', 
        id: 'block-ray-ban',
        title: 'Ray-Ban Sun-Day',
        textBlock: true,
        textBlockParts: [],
        hoverTextBlockParts: ['Shades On.', '', 'Volume Up.'],
        img: raybanSunday,
        imgUrl: '../../images/home-block-rayban.png',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'mclaren-uncovered', 
        id: 'block-mclaren-uncovered',
        title: 'McLaren Uncovered',
        textBlock: true,
        textBlockParts: [],
        hoverTextBlockParts: ['All Will ', '', 'Be Revealed'],
        img: mclarenUncovered,
        imgUrl: '../../images/home-block-mclaren.png',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },

    
    { 
        project: 'toyota-gazoo', 
        id: 'block-toyota-gazoo',
        title: 'Toyota Gazoo',
        textBlock: true,
        textBlockParts: [],
        hoverTextBlockParts: ['Your New', '', 'Adrenaline Rush'],
        img: toyotaGazoo,
        imgUrl: '../../images/home-toyota-gazoo.jpeg',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'porsche-unseen', 
        id: 'block-porsche-unseen',
        title: 'Porsche Unseen',
        textBlock: false,
        textBlockParts: [],
        hoverTextBlockParts: ['Seeing the ','','Unseen'],
        img: porscheUnseen,
        imgUrl: '../../images/home-porsche-purple.png',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'misfit-wrapping-co', 
        id: 'block-misfit-wrapping-co',
        title: 'Misfit Wrapping Co.',
        textBlock: true,
        textBlockParts: ['Wrapped with love', '— assuming we have that in stock'],
        hoverTextBlockParts: ['Come Together and','','Misfit Right In'],
        img: misfitWrapping,
        imgUrl: '../../images/home-misfit-wrapping.jpeg',
        tag: 'Branding',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'from-where-i-mini', 
        id: 'block-from-where-i-mini',
        title: 'MINI COUNTRYMAN LAUNCH',
        textBlock: false,
        textBlockParts: [],
        hoverTextBlockParts: ['#From Where ','I', 'MINI'],
        img: fromWhereIMINI,
        imgUrl: '../../images/home-from-where-imini.jpeg',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'babo', 
        id: 'block-babo',
        title: 'Babo',
        textBlock: false,
        textBlockParts: [],
        hoverTextBlockParts: ['Bibimbap to','', 'bam bam ba-bam'],
        img: babo,
        imgUrl: '../../images/home-babo.gif',
        tag: 'Branding',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'michelin-guide-virtual-family-meal', 
        id: 'block-michelin-guide-virtual-family-meal',
        title: 'Michelin Guide Virtual Family Meal',
        textBlock: true,
        textBlockParts: ['There\'s room for everyone', 'at the virtual table'],
        hoverTextBlockParts: ['There\'s room for everyone','', 'at the virtual table'],
        img: michelinFamily,
        imgUrl: '../../images/home-michelin-family.png',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'cannacon', 
        id: 'block-cannacon',
        title: 'Cannacon',
        textBlock: false,
        textBlockParts: [],
        hoverTextBlockParts: ['Meet the','', 'Industry Growers'],
        img: cannacon,
        imgUrl: '../../images/home-cannacon.jpeg',
        tag: 'Branding',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'jamestown-ultimate-showdown', 
        id: 'block-jamestown-ultimate-showdown',
        title: 'Jamestown: The Ultimate Showdown',
        textBlock: false,
        textBlockParts: [],
        hoverTextBlockParts: ['Come Together','and', 'Misfit Right In'],
        img: misfitGrinch, // change this
        imgUrl: '../../images/home-jt.gif',
        tag: 'Branding',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'the-giving-kitchen', 
        id: 'block-the-giving-kitchen',
        title: 'The Giving Kitchen',
        textBlock: false,
        textBlockParts: [],
        hoverTextBlockParts: ['Our Shift','Starts', 'When Yours Can\'t'],
        img: givingKitchen,
        imgUrl: '../../images/home-giving-kitchen.png',
        tag: 'Branding',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'toyota-motor-thrills', 
        id: 'block-toyota-motor-thrills',
        title: 'Toyota Motor Thrills',
        textBlock: true,
        textBlockParts: ['A test-drive that','', 'tests you right back'],
        hoverTextBlockParts: [],
        img: toyotaMotorThrills,
        imgUrl: '../../images/home-toyota-motor-thrills.jpeg',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'toyota-social', 
        id: 'block-toyota-social',
        title: 'Toyota Olympics Social',
        textBlock: true,
        textBlockParts: ['A test-drive that', 'tests you right back'],
        hoverTextBlockParts: ['Making','Team Toyota ', 'a Must Follow'],
        img: toyotaSocial,
        imgUrl: '../../images/home-toyota-social.gif',
        tag: 'Social Media',
        video: false,
        videoUrl: ''
    },
    { 
        project: 'jaguar-landrover-leadership-summit', 
        id: 'block-jaguar-landrover-leadership-summit',
        title: 'Range Rover Leadership Summit',
        textBlock: false,
        textBlockParts: [],
        hoverTextBlockParts: ['Lead','','by Example'],
        img: landroverLeadership,
        imgUrl: '../../images/home-landrover-leadership.jpeg',
        tag: 'Experiential',
        video: false,
        videoUrl: ''
    },
]


export {
    newBlockData
}