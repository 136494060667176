import React from 'react'
import { useMouse } from '../home/helpers'
import IconEye from '../../images/svg/icon-mouse-eye.svg'
import IconArrow from '../../images/svg/icon-arrow-projectnav.svg'
import IconTwitter from '../../images/svg/LinkedIn_logo.svg'
import IconIG from '../../images/svg/icon-ig.svg'
import IconClose from '../../images/svg/icon-close.svg'
import IconPlay from '../../images/svg/icon-play2.svg'

function Mouse(props) {
    const m = useMouse()

    const textTags = ['P','H1','H2','H3','H4','LABEL', 'BUTTON']

    const iconTags = ['iconEye', 'iconNextArrow', 'iconPrevArrow', 'iconTwitter',
                     'iconIG', 'iconClose', 'iconLogo', 'iconPlay', 'iconNavArrow']

    const icons = {
        iconPlay: 'play',
        iconEye: 'eye',
        iconNextArrow: 'arrow-next',
        iconPrevArrow: 'arrow-prev',
        iconNavArrow: 'arrow-next',
        iconTwitter: 'twitter',
        iconIG: 'instagram',
        iconClose: 'close',
        iconLogo: 'logo'
    }

    const classnames = typeof m.c === "string" ? m.c.indexOf(" ") > -1 ? m.c.split(" ") : [m.c] : []
    const itemclass = classnames.find(item=>iconTags.includes(item))
    const isIcon = itemclass ? itemclass.length > 0 : false
    const isText = textTags.includes(m.el)
    const size = isText || isIcon ? 8.33 : 1
    const iconClass = isIcon ? `mouse-${icons[itemclass]}` : ''

    const mouseStyle = {
        top: `${m.y}px`,
        left: `${m.x}px`,
        width: `${size}rem`,
        height: `${size}rem`,
        background: `${isIcon ? 'var(--color-main)' : 'none'}`,
        backdropFilter: `invert(${isIcon ? '0' : '1'})`,
        WebkitBackdropFilter: `invert(${isIcon ? '0' : '1'})`
    }

    return (
        <div style={mouseStyle} className={iconClass} id={'mouse-custom-wrapper'}>
            <IconPlay className={'iconPlay'}/>
            <IconEye className={'iconMouseEye'}/>
            <IconArrow className={'iconNavArrow'}/>
            <IconTwitter className={'iconMouseTwitter'}/>
            <IconIG className={'iconMouseIG'}/>
            <IconClose className={'iconMouseClose'}/>
        </div>
    )
}

export default Mouse